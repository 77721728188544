.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.homeBkColor {
  background-color: rgb(252, 198, 24);
  height: 100vh;
}
.background {
  background-image: url("assets/background.png");
  background-repeat: no-repeat, repeat;
  height: 100vh;
}
.homeBoldText {
  font-weight: 600;
  font-size: 28px;
}
.homeText {
  font-size: 18px;
  font-weight: 500;
}
.btn-circle {
  width: 170px;
  height: 45px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
}
.submit-btn{
  background-color: rgb(0, 158, 226);
}
.hf-color{
  background-color: rgb(0, 158, 226);
}
.h-size{
  width: 100%;
  height: 100px;
}
.inv-lg-text {
  font-size: 36px;
  font-weight: 600;
}
.inv-cont-text {
  font-size: 13px;
  margin-bottom: auto;
}
.inv-grey-color{
  color: darkgrey;
}
.inv-black-color {
  color: black;
}
.inv-med-text {
  font-size: 16px;
  font-weight: 700;
}
.dot-off {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: rgb(162,198,23);
}
.dot-disable {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid red;
}
.dot-on {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.inv-rect{
  border-radius: 20px;
}
.inv-card{
  padding: 20px 30px;
}
.inv-btn {
  background-color: rgb(252, 198, 24);
  width:150px;
  height:130px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  padding: 5px 15px 15px 15px;
  white-space: pre-wrap;
  color:rgb(95, 73, 3);
  border-radius: 18px;
  margin-bottom: auto;
  line-height: 1.1;
  cursor: pointer;
}
.inv-btn-icon {
  text-align: center;
  height:50px;
  margin-bottom: 5px;
}
.inv-nodata{
  text-align: center;
  font-size: 36px;
  font-weight: 800;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
  display: none;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.swiper-button-next, .swiper-button-prev {
  color:rgb(175, 172, 172) !important;
  width: 40px !important;
  height: 40px !important;
  border: solid 1px;
  margin-top: -1px !important;
  border-radius: 5px;
  top: 35% !important;
  background-color: white;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 23px !important;
  color:black;
}
.swiper-bold{
  font-weight: 700;
}
.swiper-normal{
  font-weight: 400;
}
.tk-text {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  line-height: 1.8;
}
.code-input {
  width:100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.code-label {
  margin-bottom: 16px;
}
.code-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.code-inputs input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 100px;
  width: 10%;
  border-radius: 10px;
  margin: 0 10px;
  border: 2px solid rgb(235, 235, 235);
  font-size: 38px;
  background-color: white;
}
.code-inputs input:focus {
  outline: none;
}
.modal-body{
  margin-left: 30px;
  margin-right:30px;
}
.modal-title{
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.modal-text-1{
  font-size: 30px;
  font-weight: 700;
}
.modal-text-2-bold{
  font-size: 20px;
  font-weight: 700;
}
.modal-text-2{
  font-size: 20px;
  font-weight: 400;
}
.modal-text-3{
  font-size: 16px;
}
.modal-text-4{
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.modal-text-5{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: auto;
}
.modal-text-6{
  font-weight: 500;
  margin-bottom: auto;
  margin-right: 5px;
  word-wrap: break-word;
}
.modal-button{
  border-radius: 13px;
  padding-right:15px;
  padding-left: 15px;
}
.modal-code-bk{
  background-color: whitesmoke;
  padding: 20px 0px;
}
.barcode{
  width:100%;
  height:120px;
}
.barcode svg{
  width: 100% !important;
}
.qrcode-div{
  height: "100px";
  margin: "0 auto";
  max-width: 64;
  width: "100%";
}
.qrcode{
  height: "100px"; 
  max-width: "100px"; 
  width: "100%";
}
.footer-size{
  width: 100%;
}
.footer-logo{
  width:100px;
}
.footer-text{
  color:rgb(138, 202, 233);
}
.footer-text-1{
  color: white;
  font-size: 22px;
  font-weight: 600;
}
.footer-text-2{
  font-size: 26px;
  font-weight: 700;
  color:rgb(13, 106, 168);
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}
.mtb-100 {
  margin: 100px 0px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-50 {
  padding-right: 50px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-30 {
  margin-left: 30px;
}
.mb-auto {
  margin-bottom: auto !important;
}
.vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (min-width: 992px) {
  .vl {
    border-left: 2px solid lightgray;
    height: 60px;
    position: absolute;
    left: 65%;
    margin-left: -3px;
    top: 90px;
  }
}
@media screen and (max-width: 992px) {
  .inv-btn{
    width:min-content;
  }
  .v1 {
    display: none;
  }
  .barcode svg{
    width:400px;
  }
  .ml-80 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .mtb-100 {
    margin: 30px 0px;
  }
  .mb-50 {
    margin-bottom: 30px;
  }
  .mt-50 {
    margin-top: 30px;
  }
  .xs-center {
    width:100%;
    text-align: center;
  }
  .inv-btn{
    width:unset;
    margin: 5px 10px;
  }
  .modal-body{
    margin-left: 10px;
    margin-right:10px;
  }
  .barcode svg{
    width:300px;
  }
  .inv-cont-text {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .center-text {
    text-align: center;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f8f9fa;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: .75rem;
}

.card .card-header {
  background-color: #fff;
  border-bottom: none;
}